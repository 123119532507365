<template>
  <b-table
    class="mt-1"
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
  >
    <template #cell(onumber)="data">
      <b-link
        class="text-body"
        :to="'/app/offers/view/' + data.item.id"
      >
        {{ data.item.onumber }}
      </b-link>
    </template>
    <template #cell(company)="data">
      <b-link
        class="text-body"
        :to="'/app/offers/view/' + data.item.id"
      >
        {{ data.item.company }}
        <div class="font-small-2">
          <div v-if="data.item.odate">
            <strong class="text-primary">Teklif Tarihi:</strong> {{ moment(data.item.odate).format('DD.MM.YYYY') }}
          </div>
          <div v-if="data.item.vdate">
            <strong class="text-primary">Geçerlilik Tarihi:</strong> {{ moment(data.item.vdate).format('DD.MM.YYYY') }}
          </div>
        </div>
      </b-link>
    </template>
    <template #cell(offers_status)="data">
      <div
        v-if="data.item.brand"
        class="text-success font-small-2 font-weight-bold"
      >
        {{ data.item.brand }}
      </div>
      {{ data.item.offers_status }}
      <div class="font-small-2 text-primary">
        {{ data.item.username? data.item.username : data.item.dealer_user }}
        <div
          v-if="data.item.dealer_user"
          class="font-small-2 text-muted"
        >
          (Bayi Kullanıcısı)
        </div>
      </div>
    </template>
    <template #cell(control)="data">
      <template v-if="userData.id_dealers">
        <div v-if="userData.id_dealers === data.item.id_dealers">
          <list-buttons
            primary-text="Görüntüle"
            :primary-action="'/app/offers/view/' + data.item.id"
            edit-text="Revize Oluştur"
            :edit-action="'/app/offers/revised/' + data.item.id"
            :delete-action="removeData"
            :data-id="data.item.id"
          />
        </div>
        <div v-else>
          <b-button
            variant="info"
            block
            size="sm"
            :to="'/app/offers/view/' + data.item.id"
          >
            <FeatherIcon icon="EyeIcon" />
            Görüntüle
          </b-button>
        </div>
      </template>
      <template v-else>
        <list-buttons
          primary-text="Görüntüle"
          :primary-action="'/app/offers/view/' + data.item.id"
          edit-text="Revize Oluştur"
          :edit-action="'/app/offers/revised/' + data.item.id"
          :delete-action="removeData"
          :data-id="data.item.id"
        />
      </template>
    </template>
  </b-table>
</template>
<script>
import { BTable, BLink, BButton } from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'OfferList',
  components: {
    BButton,
    BTable,
    BLink,
    ListButtons,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    removeData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
